//
// Avatar group
//

// General styles

.avatar-group {
	.avatar {
		position: relative;
		z-index: 2;
		border: 2px solid $card-bg;

		&:hover {
			z-index: 3;
		}
	}
}
